import React from "react";
import {
  GooglePlayButton,
  AppStoreButton,
  ButtonsContainer,
} from "react-mobile-app-button";

const MainContent = () => {
  return (
    <main>
      {/* Initial Content */}
      <div className="initial-content">
        <img
          src="/assets/splash_f2.png"
          alt="Flyr icon"
          className="flyr-icon"
          width={200}
          height={112.5}
        />

        <h2
          style={{
            fontSize: "42px",
            fontWeight: 600,
            letterSpacing: "-1.8px",
            textAlign: "center",
          }}
        >
          Earn while staying fit.
        </h2>

        <h1
          style={{
            fontSize: "72px",
            fontWeight: 700,
            letterSpacing: "-3.8px",
            textAlign: "center",
          }}
        >
          Flyr delivery made easy.
        </h1>

        <h2
          style={{
            fontSize: "20px",
            fontWeight: 500,
            letterSpacing: "-0.7px",
            lineHeight: "1.5em",
            textAlign: "center",
            opacity: 0.9, // Slightly reduced opacity for contrast
          }}
        >
          No folding, no bundling. <br />
          Just hustle.
        </h2>

        <ButtonsContainer>
          <AppStoreButton url={"https://apps.apple.com/au/app/flyr-go/id1671648000"} theme={"dark"} className={"custom-style"} />
          <GooglePlayButton
            url={"https://play.google.com/store/apps/details?id=com.walkingApp"}
            theme={"dark"}
            className={"custom-style"}
          />
        </ButtonsContainer>
        <div className="support-email-container">
            <span>Email</span>
            <a href="mailto:support@flyr.au" className="support-email">support@flyr.au</a>
        </div>
      </div>

      {/* Section A */}
      <section className="content-section section-a">
        <img src="/assets/preonboarding_walk.png" alt="Description for A" />
        <div className="section-text">
          <h2 className="section-primary-heading">You decide where</h2>
          <p className="section-secondary-heading">
            Pick your route. Without any boundaries.
          </p>
        </div>
      </section>

      {/* Section B */}
      <section className="content-section section-b">
        <div className="section-text">
          <h2 className="section-primary-heading">You decide when</h2>
          <p className="section-secondary-heading">
            Set your schedule. Pure flexibility.
          </p>
        </div>
        <img src="/assets/preonboarding_calendar.png" alt="Description for B" />
      </section>

      {/* Section C */}
      <section className="content-section section-c">
        <img src="/assets/preonboarding_signup.png" alt="Description for C" />
        <div className="section-text">
          <h2 className="section-primary-heading">All from your mobile</h2>
          <p className="section-secondary-heading">
            Complete autonomy. Right in your pocket.
          </p>
        </div>
      </section>
    </main>
  );
};

export default MainContent;
